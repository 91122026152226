import AnimatedPage from "../../animatedPage";
import "./style.css";

export default function Contacts() {
  return (
    <AnimatedPage>
      <div className="container">
        <div className="rekviziti">
          {/*     <p className="blue_rekvizit rekvizit_text">
            ИП Сосенко Михаил Васильевич
          </p>
          <p className="darkblue_rekvizit rekvizit_text">
            Адрес: 644109, Омская обл., г. Омск, ул. Молодова, д. 8 кв. 90
          </p>
          <p className="lightblue_rekvizit rekvizit_text">ИНН: 550617135927</p>
          <p className="darkblue_rekvizit rekvizit_text">
            ОГРНИП: 320554300060443
          </p>
          <p className="lightblue_rekvizit rekvizit_text">
            р/с: 40802810812500014353
          </p>
          <p className="darkblue_rekvizit rekvizit_text">
            Банк: ТОЧКА ПАО БАНКА "ФК ОТКРЫТИЕ"
          </p>
          <p className="lightblue_rekvizit rekvizit_text">БИК: 044525999</p>
          <p className="darkblue_rekvizit rekvizit_text">
            К/с: 30101810845250000999
          </p> */}
          <a
            href="https://opora.digital/"
            target="_blank"
            className="blue_rekvizit rekvizit_text"
            style={{ color: "white" }}
          >
            <b>OPORA.DIGITAL</b> / https://opora.digital/
          </a>
          <a
            className="lightblue_rekvizit rekvizit_text"
            href="http://wa.me/84382425892"
            target="_blank"
          >
            <b>WhatsApp</b> / https://wa.me/84382425892
          </a>
          <a
            className="lightblue_rekvizit rekvizit_text"
            href="https://t.me/oporadigital_bot"
            target="_blank"
          >
            <b>Telegram</b> / https://t.me/oporadigital_bot
          </a>
        </div>
      </div>
    </AnimatedPage>
  );
}
