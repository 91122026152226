import "./admin.css";
import adminMainPageImg from "../imgs/adminPanel.png";
import Header from "../header/Header";
import Navbar from "../navbar/Navbar";
import xls from "../imgs/xls.svg";
import people from "../imgs/people.svg";
import raz from "../imgs/raz.png";
import { Context } from "../Context";
import { useContext, useEffect, useState } from "react";
import AnimatedPage from "../animatedPage";
import { Zoom } from "react-reveal";
import { Flip } from "react-reveal";
import Grafic from "../Components/Chars";

export default function AdminMainPage() {
  const [context, setContext] = useContext(Context);
  const [sub, setSub] = useState();

  function saveTg() {
    let form = new FormData();
    form.append("tel", localStorage.tel);
    form.append("password", localStorage.password);
    form.append("tg", document.getElementById("applicationsSend").value);
    fetch("https://app.opora.digital/applications.php", {
      method: "POST",
      body: form,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data == "ok") {
          setContext({
            ...context,
            popSend: false,
          });
          alert("Ссылка успешно изменена");
        }
      });
  }
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = today.getFullYear();

  today = dd + "." + mm + "." + yyyy;
  function saveMessage() {
    let form = new FormData();
    form.append("tel", localStorage.tel);
    form.append("password", localStorage.password);
    form.append("tg", document.getElementById("messageSend").value);
    fetch("https://app.opora.digital/messages.php", {
      method: "POST",
      body: form,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data == "ok") {
          setContext({
            ...context,
            popMessage: false,
          });
          alert("Ссылка успешно изменена");
        }
      });
  }
  function getSubs() {
    let form = new FormData();
    form.append("ID", localStorage.id);
    if (localStorage.id == "26") {
      fetch("https://app.opora.digital/count2.php", {
        method: "post",
        body: form,
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setSub(data);
        });
    } else {
      fetch("https://app.opora.digital/count.php", {
        method: "post",
        body: form,
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setSub(data);
        });
    }
  }
  useEffect(() => {
    getSubs();
  }, []);
  return (
    <div className="container">
      {context.popSend && (
        <div className="pop_send">
          <div className="pop_send_body">
            <p>
              Введите ссылку на ваш аккаунт телеграм в который вы хотите
              получать <b>уведомления о заявках</b>{" "}
            </p>
            <div className="opacity_text">
              Ссылка вида: https://t.me/ваш аккаунт
            </div>
            <input type="text" className="input_text" id="applicationsSend" />
            <div className="save_send" onClick={() => saveTg()}>
              Сохранить
            </div>
          </div>
        </div>
      )}
      {context.popMessage && (
        <div className="pop_send">
          <div className="pop_send_body">
            <p>
              Введите ссылку на ваш аккаунт телеграм в который вы хотите
              получать <b>уведомления о Сообщениях</b>{" "}
            </p>
            <div className="opacity_text">
              Ссылка вида: https://t.me/ваш аккаунт
            </div>
            <input type="text" className="input_text" id="messageSend" />
            <div className="save_send" onClick={() => saveMessage()}>
              Сохранить
            </div>
          </div>
        </div>
      )}
      <div className="subscribers">
        <div className="sub_titles">
          <Zoom left>
            <h3>Подписчики</h3>
          </Zoom>
          <Zoom left>
            <p>Расчет подписчиков производится каждые сутки в 23:50</p>
          </Zoom>
          <Zoom>
            <div className="xls">
              <p>Выгрузить статистику по дням</p>
              <img src={xls} alt="" />
            </div>
          </Zoom>
        </div>
        <div className="sub_total">
          <Zoom top delay={100}>
            <div className="sub_circle">
              <div className="sub_total_num">
                <h3>{sub}</h3>
                <p>подписчиков</p>
              </div>
              <div className="vawe"></div>
            </div>
          </Zoom>
          <Zoom right delay={200}>
            <div className="sub_description">
              <img src={people} alt="" />
              <div className="date">
                <p>Сейчас</p>
                <span>{today}</span>
              </div>
              <div className="line"></div>
            </div>
          </Zoom>
        </div>
        <Flip delay={300}>
          <div className="days_gone">
            <div className="line"></div>
            <div className="days_gone_body">
              <p>Оплаченных дней:</p>
              <h3>∞</h3>
            </div>
          </div>
        </Flip>
      </div>
      <Grafic />
    </div>
  );
}
