export async function getCourses() {
  const response = await fetch("https://app.opora.digital/taro/getCourses.php");
  return response.json();
}
export async function getDop() {
  const response = await fetch("https://app.opora.digital/taro/getDop.php");
  return response.json();
}
export async function getReview() {
  const response = await fetch("https://app.opora.digital/taro/getReview.php");
  return response.json();
}
