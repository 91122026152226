import { Suspense, useEffect, useRef, useState } from "react";
import "./style.css";
import Loaders from "../../Components/Loaders/Loaders";
export default function TovarNew() {
  const [cards, setCards] = useState([]);
  const [search, setSearch] = useState("");
  const [currentTovar, setCurrentTovar] = useState({});
  const [newTovar, setNewTovar] = useState({});
  const [open, isOpen] = useState(false);
  const [visible, isViseble] = useState(false);
  const [img, setImg] = useState([]);
  const fileName = useRef(null);
  const [brand, setBrand] = useState();
  const [category, setCategory] = useState();
  const [type, setType] = useState();
  const [newImg, setNewImg] = useState("");
  const [news, isNew] = useState(false);
  const getBrand = () => {
    fetch("https://app.opora.digital/cardsApi/getBrand.php")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setBrand(data);
      });
  };
  const getType = () => {
    fetch("https://app.opora.digital/admin/getType.php")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setType(data);
      });
  };
  const getCategory = () => {
    fetch("https://app.opora.digital/admin/getCategory.php")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setCategory(data);
      });
  };
  useEffect(() => {
    getBrand();
    getCategory();
    getType();
  }, []);
  function saveEm() {
    let formData = new FormData();
    formData.append("name", currentTovar.Title);
    formData.append("price", currentTovar.Price);
    formData.append("priceOld", currentTovar.PriceOld);
    formData.append("type", currentTovar.Type);
    formData.append("text", currentTovar.Texts);
    formData.append("desc", currentTovar.Description);
    formData.append("brand", currentTovar.brand);
    formData.append("id", currentTovar.ID);
    let arrSizes = [];
    let arrDomSizes = document.getElementsByName("sizes");
    for (let i = 0; i < arrDomSizes.length; i++) {
      if (arrDomSizes[i].checked) {
        arrSizes.push(arrDomSizes[i].value);
      }
    }

    fetch("https://app.opora.digital/admin/updateCard.php", {
      method: "post",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data == "1") {
          isOpen(false);
          getTovar();
          setCurrentTovar({});
        }
      });
  }
  function addEm() {
    let formData = new FormData();
    formData.append("name", newTovar.Title);
    formData.append("price", newTovar.Price);
    formData.append("priceOld", newTovar.PriceOld);
    formData.append("type", newTovar.Type);
    formData.append("text", newTovar.Texts);
    formData.append("id", localStorage.id);
    formData.append("desc", newTovar.Description);
    formData.append("brand", newTovar.brand);
    formData.append("photo", newImg);
    let arrSizes = [];
    let arrDomSizes = document.getElementsByName("sizes");
    for (let i = 0; i < arrDomSizes.length; i++) {
      if (arrDomSizes[i].checked) {
        arrSizes.push(arrDomSizes[i].value);
      }
    }

    fetch("https://app.opora.digital/admin/addCard.php", {
      method: "post",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data == "1") {
          isNew(false);
          getTovar();
          setCurrentTovar({});
        }
      });
  }
  function updateImg(event, index) {
    let arr = img;
    let data = new FormData();
    let path = "https://app.opora.digital/";
    data.append("file", document.getElementById(event.target.id).files[0]);
    fetch("https://app.opora.digital/avatar.php", {
      method: "post",
      body: data,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let fullPath = path + data;
        arr[index] = fullPath;
        let str = arr.join(" ");

        let update = new FormData();
        update.append("id", currentTovar.ID);
        update.append("str", str);
        fetch("https://app.opora.digital/admin/updatePhoto.php", {
          method: "POST",
          body: update,
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            setImg(data[0].split(" "));
          });
      });
  }
  function addImg(event) {
    let formData = new FormData();

    Array.from(document.getElementById("filesadd").files).forEach((file) => {
      formData.append("file[]", file);
    });
    fetch("https://app.opora.digital/cardsApi/addImg.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let arr = data;
        for (let i = 0; i < arr.length; i++) {
          arr[i] = "https://app.opora.digital/cardsApi/" + arr[i];
        }
        let str = arr.join(" ");
        str = img.join(" ") + " " + str;
        let update = new FormData();
        update.append("id", currentTovar.ID);
        update.append("str", str);
        fetch("https://app.opora.digital/admin/updatePhoto.php", {
          method: "POST",
          body: update,
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            console.log(data);
            setImg(data[0].split(" "));
          });
      });
  }

  //IMGNEW

  function addImg2(event) {
    let formData = new FormData();

    Array.from(document.getElementById("filesadd").files).forEach((file) => {
      formData.append("file[]", file);
    });
    fetch("https://app.opora.digital/cardsApi/addImg.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let arr = data;
        for (let i = 0; i < arr.length; i++) {
          arr[i] = "https://app.opora.digital/cardsApi/" + arr[i];
        }
        let str = arr.join(" ");
        setNewImg(str);
      });
  }
  function deleteImg(index) {
    let arr = img.filter((em, num) => num !== index);
    console.log(img);
    let str = arr.join(" ");

    let update = new FormData();
    update.append("id", currentTovar.ID);
    update.append("str", str);
    fetch("https://app.opora.digital/admin/updatePhoto.php", {
      method: "POST",
      body: update,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setImg(data[0].split(" "));
      });
  }
  function getTovar() {
    let form = new FormData();
    form.append("id", localStorage.id);
    fetch("https://app.opora.digital/admin/Tovar.php", {
      method: "POST",
      body: form,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setCards(data);
      });
  }
  const handleChange = (event) => {
    switch (event.target.name) {
      default:
        setCurrentTovar({
          ...currentTovar,
          [event.target.name]: event.target.value,
        });
        break;
    }
  };
  const changeCat = (em) => {
    setCurrentTovar({
      ...currentTovar,
      ["Description"]: em,
    });
  };
  const changeBrand = (em) => {
    setCurrentTovar({
      ...currentTovar,
      ["brand"]: em,
    });
  };
  const changeType = (em) => {
    setCurrentTovar({
      ...currentTovar,
      ["Type"]: em,
    });
  };

  //NEW
  const handleChangeNew = (event) => {
    switch (event.target.name) {
      default:
        setNewTovar({
          ...newTovar,
          [event.target.name]: event.target.value,
        });
        break;
    }
  };
  const changeCatNew = (em) => {
    setNewTovar({
      ...newTovar,
      ["Description"]: em,
    });
  };
  const changeBrandNew = (em) => {
    setNewTovar({
      ...newTovar,
      ["brand"]: em,
    });
  };
  const changeTypeNew = (em) => {
    setNewTovar({
      ...newTovar,
      ["Type"]: em,
    });
  };
  useEffect(() => {
    if (currentTovar.Photo) {
      let photos = currentTovar.Photo.replaceAll('"', "").trim().split(" ");
      console.log(photos);
      setImg(photos);
    }
  }, [currentTovar]);
  useEffect(() => {
    if (news) {
      isOpen(false);
    }
  }, [news]);

  useEffect(() => {
    if (open && currentTovar.Editions && currentTovar.Editions.length > 0) {
      isNew(false);
      let sizes = currentTovar.Editions.substr(
        1,
        currentTovar.Editions.length - 2
      )
        .trim()
        .split(",");

      let arr = document.getElementsByName("sizes");
      for (let i = 0; i < arr.length; i++) {
        arr[i].checked = false;
      }
      for (let i = 0; i < arr.length; i++) {
        for (let j = 0; j < sizes.length; j++) {
          if (sizes[j].trim() == arr[i].value) {
            arr[i].checked = true;
          }
        }
      }
    }
  }, [open, currentTovar]);
  function deleteItem(id) {
    let formData = new FormData();
    formData.append("id", id);
    fetch("https://app.opora.digital/admin/deleteCard.php", {
      method: "post",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data == "1") {
          getTovar();
        }
      });
  }
  useEffect(() => {
    getTovar();
  }, []);

  return (
    <div className="container">
      {open && (
        <div className="popUpTovar">
          <p className="red">Редактирование:</p>
          <div className="changeText">
            <h4>Заголовок</h4>
            <input
              type="text"
              value={currentTovar.Title}
              name="Title"
              autoComplete="off"
              onChange={(e) => handleChange(e)}
            />
            <h4>Цена</h4>
            <input
              type="text"
              value={currentTovar.Price}
              name="Price"
              autoComplete="off"
              onChange={(e) => handleChange(e)}
            />
            <h4>Ссылка</h4>
            <input
              type="text"
              value={currentTovar.PriceOld}
              name="PriceOld"
              autoComplete="off"
              onChange={(e) => handleChange(e)}
            />
            <h4>Описание</h4>
            <textarea
              name="Texts"
              value={currentTovar.Texts}
              id=""
              cols="30"
              autoComplete="off"
              onChange={(e) => handleChange(e)}
              rows="10"
            ></textarea>

            <div className="relate">
              <h4>Район</h4>
              <input
                type="text"
                autoComplete="off"
                value={currentTovar.brand}
                name="brand"
                onChange={(e) => handleChange(e)}
              />
              <div className="searchCat">
                {brand &&
                  brand.length > 0 &&
                  brand.map((em, key) => {
                    return (
                      <div
                        key={key}
                        name="Description"
                        onClick={() => changeBrand(em.brand)}
                      >
                        {em.brand}
                      </div>
                    );
                  })}
              </div>
            </div>

            <h4>Фото</h4>
            <div className="images">
              {img &&
                img.length > 0 &&
                img.map((em, index) => {
                  return (
                    <div className="imagesDiv" key={index}>
                      <img src={em} alt="" />
                      <input
                        type="file"
                        onChange={(e) => updateImg(e, index)}
                        name=""
                        id={"file" + index}
                      />
                      <label htmlFor={"file" + index}>Заменить</label>
                      <div
                        className="delImg"
                        onClick={() => {
                          deleteImg(index);
                        }}
                      >
                        ✕
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="newImg">
              <h4>Добавить новое изображение</h4>
              <input
                type="file"
                name="file"
                id="filesadd"
                onChange={(e) => {
                  addImg(e);
                }}
                multiple="multiple"
                ref={fileName}
                style={{ display: "none" }}
              />
              <label htmlFor="filesadd">Загрузить Изображения</label>
            </div>

            <h4>Параметры</h4>
            {/*   <div className="laster">
              <input type="checkbox" name="new" id="" value="XXL" />
              <p>Новое?</p>
            </div>
            <div className="laster">
              <input type="checkbox" name="opac" id="" value="XXL" />
              <p>Видимость?</p>
            </div> */}
          </div>
          <div className="tovarButtons">
            <div className="closeMe" onClick={() => isOpen(false)}>
              Отменить
            </div>
            <div className="saveTovar" onClick={() => saveEm()}>
              Сохранить
            </div>
          </div>
        </div>
      )}
      {news && (
        <div className="popUpTovar">
          <p className="red">Новый обьект:</p>
          <div className="changeText">
            <h4>Заголовок</h4>
            <input
              type="text"
              value={newTovar.Title}
              name="Title"
              autoComplete="off"
              onChange={(e) => handleChangeNew(e)}
            />
            <h4>Цена</h4>
            <input
              type="text"
              value={newTovar.Price}
              name="Price"
              autoComplete="off"
              onChange={(e) => handleChangeNew(e)}
            />
            <h4>Ссылка</h4>
            <input
              type="text"
              value={newTovar.PriceOld}
              name="PriceOld"
              autoComplete="off"
              onChange={(e) => handleChangeNew(e)}
            />
            <h4>Описание</h4>
            <textarea
              name="Texts"
              value={newTovar.Texts}
              id=""
              cols="30"
              autoComplete="off"
              onChange={(e) => handleChangeNew(e)}
              rows="10"
            ></textarea>

            <div className="relate">
              <h4>Район</h4>
              <input
                type="text"
                autoComplete="off"
                value={newTovar.brand}
                name="brand"
                onChange={(e) => handleChangeNew(e)}
              />
              <div className="searchCat">
                {brand &&
                  brand.length > 0 &&
                  brand.map((em, key) => {
                    return (
                      <div
                        key={key}
                        name="Description"
                        onClick={() => changeBrandNew(em.brand)}
                      >
                        {em.brand}
                      </div>
                    );
                  })}
              </div>
            </div>

            <h4>Фото</h4>

            <div className="newImg">
              <h4>Добавить новое изображение</h4>
              <input
                type="file"
                name="file"
                id="filesadd"
                onChange={(e) => {
                  addImg2(e);
                }}
                multiple="multiple"
                ref={fileName}
                style={{ display: "none" }}
              />
              <label htmlFor="filesadd">Загрузить Изображения</label>
            </div>
            <h4 style={{ marginTop: "1vw" }}>Размеры</h4>

            {/*   <h4>Параметры</h4>
            <div className="laster">
              <input type="checkbox" name="new" id="" value="XXL" />
              <p>Новое?</p>
            </div>
            <div className="laster">
              <input type="checkbox" name="opac" id="" value="XXL" />
              <p>Видимость?</p>
            </div> */}
          </div>
          <div className="tovarButtons">
            <div className="closeMe" onClick={() => isNew(false)}>
              Отменить
            </div>
            <div className="saveTovar" onClick={() => addEm()}>
              Сохранить
            </div>
          </div>
        </div>
      )}
      <div className="instruments">
        <input
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          name=""
          placeholder="Поиск по имени"
          id=""
        />
        <div className="newTovar" onClick={() => isNew(true)}>
          Новый товар
        </div>
        <input type="checkbox" id="vis" onChange={() => isViseble(!visible)} />
        <label htmlFor="vis">Только видимые</label>
      </div>
      <div className="tovarEm mainTovar">
        <p className="id">ID</p>
        <img src="" alt="" />
        <p className="tovarTitle">Название</p>
        <p className="price">Цена</p>
        <p className="type">Тип</p>
        <p className="type">Категория</p>
        <p className="type" style={{ fontWeight: "bold" }}>
          Видимость
        </p>
        <p className="type">Новое</p>
        <p className="type">Описание</p>
        <p className="deletTovar" style={{ opacity: "0" }}>
          ✕
        </p>
      </div>

      <div className="tovarList">
        {cards &&
          cards.length > 0 &&
          cards.map((em, index) => {
            if (em.Title.toLowerCase().includes(search.toLowerCase())) {
              if (visible) {
                if (em.availability == "1") {
                  let photo = em.Photo.replaceAll('"', "").trim().split(" ")[0];
                  return (
                    <div
                      key={index}
                      className="tovarEm"
                      onClick={() => {
                        setCurrentTovar(em);
                        isOpen(true);
                      }}
                      style={
                        currentTovar && currentTovar.ID == em.ID
                          ? { border: "2px solid #00c2ff" }
                          : {}
                      }
                    >
                      <p className="id">{em.ID}</p>
                      <img src={photo} alt="" />
                      <p className="tovarTitle">{em.Title}</p>
                      <p className="price">{em.Price} $</p>
                      <p className="type">{em.Type}</p>
                      <p className="type">{em.Description}</p>
                      <p className="type" style={{ fontWeight: "bold" }}>
                        {em.availability == "1" ? "Видимый" : "Скрытый"}
                      </p>
                      <p className="type">
                        {em.New == "1" ? "Новинка" : "Обычный"}
                      </p>
                      <p className="type" style={{ borderRight: "none" }}>
                        {em.Texts != ";" && em.Texts != "<br /><br />"
                          ? "Есть"
                          : "отсутствует"}
                      </p>
                      <p
                        className="deletTovar"
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteItem(em.ID);
                        }}
                      >
                        ✕
                      </p>
                    </div>
                  );
                }
              } else {
                let photo = em.Photo.replaceAll('"', "").trim().split(" ")[0];
                return (
                  <div
                    key={index}
                    className="tovarEm"
                    onClick={() => {
                      setCurrentTovar(em);
                      isOpen(true);
                    }}
                    style={
                      currentTovar && currentTovar.ID == em.ID
                        ? { border: "2px solid #00c2ff" }
                        : {}
                    }
                  >
                    <p className="id">{em.ID}</p>
                    <img src={photo} alt="" />
                    <p className="tovarTitle">{em.Title}</p>
                    <p className="price">{em.Price} руб.</p>
                    <p className="type">{em.Type}</p>
                    <p className="type">{em.Description}</p>
                    <p className="type" style={{ fontWeight: "bold" }}>
                      {em.availability == "1" ? "Видимый" : "Скрытый"}
                    </p>
                    <p className="type">
                      {em.New == "1" ? "Новинка" : "Обычный"}
                    </p>
                    <p className="type" style={{ borderRight: "none" }}>
                      {em.Texts != ";" && em.Texts != "<br /><br />"
                        ? "Есть"
                        : "отсутствует"}
                    </p>
                    <p
                      className="deletTovar"
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteItem(em.ID);
                      }}
                    >
                      ✕
                    </p>
                  </div>
                );
              }
            }
          })}
      </div>
    </div>
  );
}
