import { useEffect, useRef, useState } from "react";
import "./style.css";
import cloud from "../../../imgs/cloud.svg";
import ok from "./ok.png";
import delete2 from "../../../imgs/delete.svg";
import Notification from "../../../Components/Notification";
import AnimatedPage from "../../../animatedPage";
export default function Vebinars() {
  const [stocks, setStocks] = useState([]);
  const [notification, setNotification] = useState("");
  const [currentAction, setCurrentAction] = useState();
  const [file1, setFile1] = useState(false);
  const [file2, setFile2] = useState(false);
  const [price, setPrice] = useState("");
  const [title, setTitle] = useState("");
  const [hots, setHots] = useState("Вебинар");
  const [changeTitle, setChangeTitle] = useState("");
  const [changeImg, setChangeImage] = useState("");
  const [changePrice, setChangerice] = useState("");
  const [changeHots, setChangeHots] = useState("");
  const [currentEm, setCurrentEm] = useState({});
  const [changePop, isChangePop] = useState(false);
  const [city, setCity] = useState("");
  const [cityChange, setCityChange] = useState("");
  useEffect(() => {
    if (currentEm) {
      setChangeTitle(currentEm.title);
      setChangeImage(currentEm.photoUrl);
      setChangerice(currentEm.price);
      setChangeHots(currentEm.hots);
      setCityChange(currentEm.City);
    }
  }, [currentEm]);
  const fileName = useRef(null);
  const fileName2 = useRef(null);
  function deleteMe(id) {
    let formData = new FormData();
    formData.append("ID", id);
    fetch("https://app.opora.digital/deleteObj.php", {
      method: "POST",
      body: formData,
    }).then((response) => {
      if (response.status == 200) {
        getNotification("Обьект удален");
        getStock();
      }
    });
  }
  function getNotification(string) {
    setNotification(string);
    let div = document.getElementsByClassName("notification")[0];
    div.style = "top: 1vw";
    setTimeout(() => {
      div.style = "top: -5vw";
    }, 2000);
  }
  function onChange() {
    let saveData = new FormData();

    saveData.append("title", title);
    saveData.append("hots", hots);
    saveData.append("price", price);
    saveData.append("city", city);
    saveData.append("ID", localStorage.id);
    fetch("https://app.opora.digital/addObject.php", {
      method: "post",
      body: saveData,
    }).then((response) => {
      if (response.status == 200) {
        getNotification("Обьект добавлен");
        getStock();
        setTitle("");
        setPrice("");
      }
    });
  }

  function getStock() {
    let formData = new FormData();
    formData.append("ID", localStorage.id);
    fetch("https://app.opora.digital/getEscape.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setStocks(data);
      });
  }
  useEffect(() => {
    getStock();
  }, []);
  const changeItem = () => {
    let formData = new FormData();

    if (file2) {
      const data = new FormData();
      let path1 = "https://app.opora.digital/";
      data.append("file", fileName2.current.files[0]);
      fetch("https://app.opora.digital/avatar.php", {
        method: "post",
        body: data,
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          path1 = path1 + data;
          formData.append("url", path1);
          formData.append("title", changeTitle);
          formData.append("price", changePrice);
          formData.append("hots", changeHots);
          formData.append("ID", localStorage.id);
          formData.append("IDem", currentEm.ID);
          formData.append("city", cityChange);
          fetch("https://app.opora.digital/setEscape.php", {
            method: "post",
            body: formData,
          }).then((response) => {
            if (response.status == 200) {
              isChangePop(false);
              getStock();
              setFile2(false);
              getNotification("Обьект изменен");
            }
          });
        });
    } else {
      formData.append("url", changeImg);
      formData.append("title", changeTitle);
      formData.append("price", changePrice);
      formData.append("hots", changeHots);
      formData.append("ID", localStorage.id);
      formData.append("IDem", currentEm.ID);
      formData.append("city", cityChange);
      fetch("https://app.opora.digital/setEscape.php", {
        method: "post",
        body: formData,
      }).then((response) => {
        if (response.status == 200) {
          isChangePop(false);
          getStock();
          setFile2(false);
          getNotification("Обьект изменен");
        }
      });
    }
  };
  return (
    <AnimatedPage>
      <div className="container">
        <div className="flexCont">
          {changePop && (
            <div className="popChange">
              <div className="popBodyChange">
                <div className="formActions">
                  <button onClick={() => isChangePop(false)}>Закрыть</button>
                  <p className="add_me">Изменить обьект</p>
                  {/*     <div className="imgAdd">
                    <p>
                      Загрузите <br /> изображение
                    </p>
                    <input
                      type="file"
                      onChange={() => setFile2(true)}
                      name="file"
                      ref={fileName2}
                      id="first"
                    />
                    <img src={fileName2 ? ok : cloud} alt="" />
                    <label htmlFor="first">Загрузить</label>
                  </div> */}
                  <h4>Заголовок:</h4>
                  <input
                    type="text"
                    placeholder="Заголовок"
                    value={changeTitle}
                    onChange={(e) => setChangeTitle(e.target.value)}
                  />
                  <h4>Цена:</h4>
                  <input
                    type="text"
                    placeholder="Цена"
                    value={changePrice}
                    onChange={(e) => setChangerice(e.target.value)}
                  />
                  <h4>Hots</h4>
                  <input
                    type="text"
                    placeholder="Hots"
                    value={changeHots}
                    onChange={(e) => setChangeHots(e.target.value)}
                  />
                  <h4>Город/Страна</h4>
                  <input
                    type="text"
                    placeholder="Город/Страна"
                    value={cityChange}
                    onChange={(e) => setCityChange(e.target.value)}
                  />
                  <div className="button" onClick={changeItem}>
                    Сохранить
                  </div>
                </div>
              </div>
            </div>
          )}
          <Notification text={notification} />
          <div className="actionsCont">
            <span>Вебинары</span>
            {stocks &&
              stocks.length > 0 &&
              stocks.map((img, index) => {
                if (img.hots == "Вебинар") {
                  return (
                    <div
                      key={index}
                      className="veb"
                      onClick={() => {
                        setCurrentAction(img.ID);
                        setCurrentEm(img);
                      }}
                    >
                      <article>{img.title}</article>
                      {img.ID == currentAction && (
                        <div className="panel">
                          <div
                            className="panEm"
                            onClick={() => deleteMe(img.ID)}
                          >
                            <img src={delete2} alt="" />
                            <p>Удалить</p>
                          </div>
                          {/*   <div
                            className="panEm"
                            onClick={() => isChangePop(true)}
                          >
                            <img src={delete2} alt="" />
                            <p>Редактировать</p>
                          </div> */}
                        </div>
                      )}
                    </div>
                  );
                }
              })}
          </div>
          <div className="actionsCont">
            <span>Доп материалы</span>
            {stocks &&
              stocks.length > 0 &&
              stocks.map((img, index) => {
                if (img.hots == "Доп") {
                  return (
                    <div
                      key={index}
                      className="veb"
                      onClick={() => {
                        setCurrentAction(img.ID);
                        setCurrentEm(img);
                      }}
                    >
                      <article>{img.title}</article>
                      {img.ID == currentAction && (
                        <div className="panel">
                          <div
                            className="panEm"
                            onClick={() => deleteMe(img.ID)}
                          >
                            <img src={delete2} alt="" />
                            <p>Удалить</p>
                          </div>
                          {/*  <div
                            className="panEm"
                            onClick={() => isChangePop(true)}
                          >
                            <img src={delete2} alt="" />
                            <p>Редактировать</p>
                          </div> */}
                        </div>
                      )}
                    </div>
                  );
                }
              })}
          </div>
          <div className="formActions">
            <p className="add_me">Добавить элемент</p>
            {/*      <div className="imgAdd">
              <p>
                Загрузите <br /> изображение
              </p>
              <input
                type="file"
                onChange={() => {
                  setFile1(true);
                }}
                name="file"
                ref={fileName}
                id="first"
              />
              <img src={file1 ? ok : cloud} alt="" />
              <label htmlFor="first">Загрузить</label>
            </div> */}
            <div className="radioB">
              <input
                type="radio"
                name="ser"
                id="kek11"
                value="Вебинар"
                defaultChecked
                onClick={() => {
                  setHots("Вебинар");
                }}
              />
              <label htmlFor="kek11">Вебинар</label>
            </div>
            <div className="radioB">
              <input
                type="radio"
                name="ser"
                id="kek12"
                value="Вебинар"
                onClick={() => {
                  setHots("Доп");
                }}
              />
              <label htmlFor="kek12">Доп материалы</label>
            </div>
            <input
              type="text"
              placeholder="Заголовок"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <input
              type="text"
              placeholder="Ссылка (видео/файл)"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />

            <div
              className="button"
              style={
                title.length > 0 && price.length > 0
                  ? {}
                  : { pointerEvents: "none", opacity: "0.6" }
              }
              onClick={onChange}
            >
              Сохранить
            </div>
          </div>
        </div>
      </div>
    </AnimatedPage>
  );
}
