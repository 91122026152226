import "./App.css";
import AdminLogin from "./adminPages/adminLogin.jsx";
import AdminDogovor from "./adminPages/adminDogovor";
import AdminMainPage from "./adminPages/adminMainPage";
import AdminRegister from "./adminPages/adminRegister.jsx";
import { Routes, Route } from "react-router-dom";
import { routes } from "./routes";
import Header from "./header/Header";
import AdminMainTable from "./adminPages/AdminMainTable/AdminMainTable";
import Navbar from "./navbar/Navbar";
import "./fonts/stylesheet.css";
import React from "react";
import { Context } from "./Context.js";
import { useState } from "react";
import Actions from "./adminPages/Actions/Actions";
import Services from "./adminPages/Services";
import Contacts from "./adminPages/Contacts";
import Clients from "./adminPages/Clients";
import { AnimatePresence } from "framer-motion";
import Doctors from "./adminPages/Doctors";
import { Voice } from "./Voice";
import Objects from "./EstatePages/Objects";
import Orders from "./Magazine/Orders";
import Tovar from "./Magazine/Tovar";
import Chat from "./adminPages/Chat";
import MainShop from "./Magazine/MainShop";
import Razdel from "./EstatePages/Razdel/index.jsx";
import Activation from "./adminPages/BALI/Activation/index.jsx";
import Vebinars from "./adminPages/BALI/Vebinars/index.jsx";
import TovarNew from "./Magazine/ObjectsNew/index.jsx";
import Dop from "./Taro/Dop/index.jsx";
import Courses from "./Taro/Courses/index.jsx";
import { ReactNotifications, Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css";
import Reviews from "./Taro/Reviews/index.jsx";
import Zad from "./Taro/Zad/index.jsx";
function App() {
  const [popSend, isPopSend] = useState({
    popSend: false,
    profileSend: false,
    contacts: false,
    popMessage: false,
  });
  const [voise, setVoice] = useState("");
  return (
    <AnimatePresence wait>
      <Context.Provider value={[popSend, isPopSend]}>
        <Voice.Provider value={[voise, setVoice]}>
          <div className="App">
            <ReactNotifications />
            {window.location.pathname != routes.login &&
              window.location.pathname != routes.register &&
              window.location.pathname != "/" &&
              window.location.pathname != routes.dogovor && (
                <div style={{ position: "fixed" }}>
                  <Navbar />
                  <Header />
                </div>
              )}

            <Routes>
              {/* Недвижимость */}
              <Route path={routes.objects} element={<Objects />} />
              <Route path={routes.razdel} element={<Razdel />} />
              <Route path="/" element={<AdminLogin />} />
              <Route path={routes.login} element={<AdminLogin />} />
              <Route path={routes.chat} element={<Chat />} />
              <Route path={routes.register} element={<AdminRegister />} />
              <Route path={routes.dogovor} element={<AdminDogovor />} />
              <Route path={routes.actions} element={<Actions />} />
              <Route path={routes.main} element={<AdminMainPage />} />
              <Route path={routes.clients} element={<Clients />} />
              <Route path={routes.MainPageShop} element={<MainShop />} />
              <Route path={routes.contacts} element={<Contacts />} />
              <Route path={routes.services} element={<Services />} />
              <Route path={routes.usersBali} element={<Activation />} />
              <Route path={routes.orders} element={<Orders />} />
              <Route path={routes.tovar} element={<Tovar />} />
              <Route path={"/tovar2"} element={<TovarNew />} />
              <Route path={"/elements"} element={<Vebinars />} />
              <Route path={routes.doctors} element={<Doctors />} />
              <Route
                path={routes.adminMainTable}
                element={<AdminMainTable />}
              />

              <Route path={routes.dop} element={<Dop />} />
              <Route path={routes.courses} element={<Courses />} />
              <Route path={"/zadanie"} element={<Zad />} />
              <Route path={"/reviews"} element={<Reviews />} />
            </Routes>
          </div>
        </Voice.Provider>
      </Context.Provider>
    </AnimatePresence>
  );
}

export default App;
