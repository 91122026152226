import "./style.css";
import AnimatedPage from "../../animatedPage";
import { useEffect, useState } from "react";
import ok from "../Actions/ok.png";
import cloud from "../../imgs/cloud.svg";
export default function Doctors() {
  const [doctors, setDoctors] = useState([]);
  const [fio, setFio] = useState("");
  const [info, setInfo] = useState("");
  const [education, setEducation] = useState("");
  const [stage, setStage] = useState("");
  const [file, isFile] = useState(false);
  function getDocs() {
    let formData = new FormData();
    formData.append("ID", localStorage.id);
    fetch("https://app.opora.digital/getDoctors.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setDoctors(data);
      });
  }
  useEffect(() => {
    getDocs();
  }, []);
  return (
    <AnimatedPage>
      <div className="container">
        <div className="docFlex">
          <div className="doctors">
            {doctors &&
              doctors.length > 0 &&
              doctors.map((em, index) => {
                return (
                  <div className="cardDoc">
                    <img src={em.photoList} alt="" />
                    <div>
                      <h4>{em.name} </h4>
                      <p>{em.education}</p>
                      <article>{em.info}</article>
                      <ul>
                        <li>
                          <b>Стаж:</b> {em.stage} (лет)
                        </li>
                        <li>
                          <b>Процедур:</b> {em.servicesNum}
                        </li>
                      </ul>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="formDoctor">
            <h4>Добавить услугу</h4>
            <input
              type="text"
              placeholder="Название"
              value={fio}
              onChange={(e) => setFio(e.target.value)}
            />
            <input
              type="file"
              name=""
              id="yous"
              style={{ display: "none" }}
              onChange={() => isFile(true)}
            />
            <label htmlFor="yous" className="yous">
              <p>Фото</p> <img src={file ? ok : cloud} alt="" />
              <div className="button">Загрузить</div>
            </label>

            <input
              type="number"
              placeholder="Цена"
              value={stage}
              onChange={(e) => setStage(e.target.value)}
            />
            <div
              className="button"
              style={
                file && fio.length > 0
                  ? {}
                  : { pointerEvents: "none", opacity: "0.6" }
              }
            >
              Сохранить
            </div>
          </div>
        </div>
      </div>
    </AnimatedPage>
  );
}
