import { useEffect, useRef, useState } from "react";
import "./style.css";
import ava from "./ava.jpg";
import you from "./ava.png";
export default function Chat() {
  const [messages, setMessages] = useState([]);
  const [text, setText] = useState("");
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get("key");
  const name = urlParams.get("name");
  const chat = useRef(null);
  const [balls, setBalls] = useState();
  function sendMessage() {
    let txt = text;
    let form = new FormData();
    form.append("text", txt);
    form.append("parse_mode", "html");

    fetch(
      "https://api.telegram.org/bot" +
        localStorage.token +
        "/sendMessage?chat_id=" +
        myParam,
      {
        method: "post",
        body: form,
      }
    ).then((response) => {
      if (response.status == 200) {
        let formData = new FormData();
        formData.append("idUser", myParam);
        formData.append("text", text);
        formData.append("idOwner", localStorage.id);
        fetch("https://app.opora.digital/mag/sendFromBot.php", {
          method: "post",
          body: formData,
        }).then((response) => {
          if (response.status == 200) {
            getChat();
            setText("");
          }
        });
      }
    });
  }
  function sendMessageBl() {
    alert("Баллы обновлены");
    let txt =
      "Ваше количество баллов обновлено. На вашем счету " + balls + " баллов.";
    let form = new FormData();
    form.append("text", txt);
    form.append("parse_mode", "html");

    fetch(
      "https://api.telegram.org/bot" +
        localStorage.token +
        "/sendMessage?chat_id=" +
        myParam,
      {
        method: "post",
        body: form,
      }
    ).then((response) => {
      if (response.status == 200) {
        let formData = new FormData();
        formData.append("idUser", myParam);
        formData.append("text", txt);
        formData.append("idOwner", localStorage.id);
        fetch("https://app.opora.digital/mag/sendFromBot.php", {
          method: "post",
          body: formData,
        }).then((response) => {
          if (response.status == 200) {
            getChat();
            setText("");
          }
        });
      }
    });
  }
  function getChat() {
    let formData = new FormData();
    formData.append("idUser", myParam);
    formData.append("idOwner", localStorage.id);
    fetch("https://app.opora.digital/mag/getChat.php", {
      method: "post",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setMessages(data);
      });
  }
  useEffect(() => {
    const timer = setInterval(() => {
      getChat();
    }, 5000);

    // очистка интервала
    return () => clearInterval(timer);
  });
  function getBalls() {
    let formData = new FormData();
    formData.append("id", myParam);
    fetch("https://app.opora.digital/bali/getBalls.php", {
      method: "post",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setBalls(data[0].Balls);
      });
  }
  function sendBalls() {
    let formData = new FormData();
    formData.append("id", myParam);
    formData.append("act", balls);
    fetch("https://app.opora.digital/bali/sendBalls.php", {
      method: "post",
      body: formData,
    })
      .then((response) => {})
      .then((data) => {
        getBalls();
        sendMessageBl();
      });
  }
  function getEnt(e) {
    if (e.keyCode === 13 && text.length > 0) {
      sendMessage();
    }
  }
  useEffect(() => {
    // document.addEventListener("keydown", (e) => getEnt(e));
    getChat();
    getBalls();
    // return () => window.removeEventListener("keydown", (e) => getEnt(e));
  }, []);
  useEffect(() => {
    chat.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);
  return (
    <div className="container" style={{ paddingTop: "20px" }}>
      <h4 className="titleChat">Чат с пользователем {name || "User"}</h4>
      <div className="messages">
        {messages.length > 0 &&
          messages.map((elem, index) => {
            return (
              <div key={index}>
                {elem.Bot != "1" && (
                  <article>
                    <img src={ava} alt="" />
                    <p>user</p>
                  </article>
                )}
                {elem.Bot == "1" ? (
                  <article>
                    <img src={you} alt="" />
                    <p>Вы</p>
                  </article>
                ) : (
                  <p></p>
                )}
                <div style={elem.Bot == "1" ? { background: "#f0fbff" } : {}}>
                  {elem.textMessage}{" "}
                  <span>{elem.timeMessage.split(" ")[1]}</span>
                </div>
              </div>
            );
          })}
        <p ref={chat} />
      </div>
      <div className="addMessageText">
        <input
          type="text"
          placeholder="Текст..."
          value={text}
          onChange={(e) => setText(e.target.value)}
          id="kek"
          autoComplete="off"
        />
        <div
          className="sendMessage"
          onClick={() => text.length > 0 && sendMessage()}
        >
          Отправить
        </div>
        <input
          type="text"
          value={balls}
          onChange={(e) => setBalls(e.target.value)}
          className="balls"
        />
        <div className="sendMessage" onClick={() => sendBalls()}>
          Сохранить
        </div>
      </div>
    </div>
  );
}
