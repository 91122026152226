import { useEffect, useState } from "react";
import "./style.css";
export default function MainShop() {
  const [cards, setCards] = useState([]);
  const [currentTovar, setCurrentTovar] = useState({});
  const [text, setText] = useState("");
  const [news, setNews] = useState([]);
  const [openTovar, setOpenTovar] = useState(false);
  const [newElem, setNewElem] = useState();
  function saveEm() {
    let formData = new FormData();
    formData.append("ID", currentTovar);
    formData.append("idCard", newElem);
    fetch("https://app.opora.digital/change.php", {
      method: "POST",
      body: formData,
    }).then((response) => {
      if (response.status == 200) {
        getCards();
        setText("");
        setOpenTovar(false);
      }
    });
  }
  const getCards = () => {
    fetch("https://app.opora.digital/cardsApi/getCards.php")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setCards(data);
      });
  };
  useEffect(() => {
    getCards();
  }, []);
  useEffect(() => {
    if (text.length > 0) {
      let formData = new FormData();
      formData.append("text", text);
      fetch("https://app.opora.digital/search.php", {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data.includes("error")) {
            setNews([]);
          } else {
            setNews(data);
          }
        });
    } else {
      setNews([]);
    }
  }, [text]);
  return (
    <div className="container">
      {openTovar && (
        <div className="popUpPos">
          <h4>Поменять позицию</h4>
          <p>Найти новый товар</p>
          <input
            type="text"
            placeholder="Наименование товара"
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
          <div className="resultsPos">
            {news.length > 0 &&
              news.map((em, index) => {
                let photo = em.Photo.replaceAll('"', "").trim().split(" ")[0];
                return (
                  <div
                    className="newPos"
                    key={index}
                    onClick={() => {
                      setNewElem(em.ID);
                      setText(em.Title);
                    }}
                  >
                    <img src={photo} alt="" />
                    <p>{em.Title}</p>
                  </div>
                );
              })}
          </div>
          <div className="tovarButtons">
            <div className="closeMe" onClick={() => setOpenTovar(false)}>
              Отменить
            </div>
            <div className="saveTovar" onClick={() => saveEm()}>
              Сохранить
            </div>
          </div>
        </div>
      )}
      <div className="tovarList">
        {cards.length > 0 &&
          cards.map((em, index) => {
            console.log(em.ID);
            let photo = em.Photo.replaceAll('"', "").trim().split(" ")[0];
            return (
              <div
                key={index}
                className="tovarEm"
                onClick={() => {
                  setOpenTovar(true);
                  setCurrentTovar(index + 1);
                }}
                style={
                  currentTovar && currentTovar == index + 1
                    ? { border: "2px solid #00c2ff" }
                    : {}
                }
              >
                <p className="id">{index + 1}</p>
                <img src={photo} alt="" />
                <p className="tovarTitle">{em.Title}</p>
                <p className="price">{em.Price} руб.</p>
                <p className="type">{em.Type}</p>
                <p className="type">{em.Description}</p>
                <p className="type" style={{ fontWeight: "bold" }}>
                  {em.availability == "1" ? "Видимый" : "Скрытый"}
                </p>
                <p className="type">{em.New == "1" ? "Новинка" : "Обычный"}</p>
                <p className="type" style={{ borderRight: "none" }}>
                  {em.Texts != ";" && em.Texts != "<br /><br />"
                    ? "Есть"
                    : "отсутствует"}
                </p>
              </div>
            );
          })}
      </div>
    </div>
  );
}
