import { useEffect, useState } from "react";
import "./style.css";
import { Store } from "react-notifications-component";
import { getReview } from "../../Api";
export default function Reviews() {
  const [link, setLink] = useState("");
  const [reviews, setReviews] = useState([]);
  const [currentReview, setCurrentReview] = useState();
  function saveReview() {
    let formData = new FormData();
    formData.append("link", link);

    fetch("https://app.opora.digital/taro/addReview.php", {
      method: "POST",
      body: formData,
    }).then((response) => {
      Store.addNotification({
        title: "Уведомление",
        message: "Отзыв успешно добавлен",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__bounceIn"],
        animationOut: ["animate__animated", "animate__backOutUp"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      setLink("");
      getReview().then((data) => {
        setReviews(data);
      });
    });
  }
  useEffect(() => {
    getReview().then((data) => {
      setReviews(data);
    });
  });

  function deleteReview() {
    let formData = new FormData();

    formData.append("ID", currentReview.ID);
    fetch("https://app.opora.digital/taro/deleteReview.php", {
      method: "POST",
      body: formData,
    }).then((response) => {
      Store.addNotification({
        title: "Уведомление",
        message: "Отзыв успешно Удален",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__bounceIn"],
        animationOut: ["animate__animated", "animate__backOutUp"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      getReview().then((data) => {
        setReviews(data);
      });
    });
  }
  return (
    <div className="container flexCont">
      <div className="coursesItems">
        {reviews &&
          reviews.length > 0 &&
          reviews.map((em, index) => {
            return (
              <div
                className="setCour"
                style={
                  currentReview && currentReview.ID == em.ID
                    ? { border: "1px solid black" }
                    : {}
                }
                onClick={() => setCurrentReview(em)}
                key={index}
              >
                {em.Link}
              </div>
            );
          })}
      </div>
      <div className="panelRed">
        <p onClick={() => deleteReview()}>Удалить</p>
      </div>
      <div className="addForm">
        <h3>Добавить отзыв</h3>
        <input
          type="text"
          value={link}
          onChange={(e) => setLink(e.target.value)}
          placeholder="Ссылка"
        />
        <div className="addCourse" onClick={() => saveReview()}>
          Добавить
        </div>
      </div>
    </div>
  );
}
