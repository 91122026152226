import { motion } from "framer-motion";
import React from "react";
const animations = {
  initial: { opacity: 0, x: 100, width: "100%" },
  animate: { opacity: 1, x: 0, width: "100%" },
  exit: { opacity: 0, x: -150, width: "100%" },
};

export default function AnimatedPage({ children }) {
  return (
    <motion.div
      className="animated_page"
      variants={animations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.1 }}
    >
      {children}
    </motion.div>
  );
}
