import { useEffect, useState } from "react";
import "./style.css";
import { Form } from "react-router-dom";
const typeOrders = [
  "Создан",
  "В сборке",
  "В доставке",
  "Ожидает получения",
  "Получен",
  "Готов к самовывозу",
  "Отменен",
];
export default function Orders() {
  const [orders, setOrders] = useState([]);
  const [currentType, setCurrentType] = useState(typeOrders[0]);
  const [openPop, isOpenPop] = useState(false);
  const [message, setMessage] = useState("");
  function getOrders() {
    fetch("https://app.opora.digital/getOrders.php")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setOrders(data);
      });
  }
  const formatDate = (date) => {
    let d = new Date(date);
    let month = (d.getMonth() + 1).toString();
    let day = d.getDate().toString();
    let year = d.getFullYear();
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    return [year, month, day].join("-");
  };
  function ChangeStatus(em, status) {
    let formData = new FormData();
    formData.append("id", em.ID);
    formData.append("status", status);
    fetch("https://app.opora.digital/cardsApi/updateOrder.php", {
      method: "POST",
      body: formData,
    }).then((response) => {
      if (response.status == 200) {
        let details = JSON.parse(em.details)[1].replace("Сумма платежа: ", "");

        let txt = `Статус вашего заказа изменился ✅

Ваш заказ от ${formatDate(em.timeOrder)} на сумму ${details} ${status}.

Остались вопросы?
<a href="https://t.me/rarestore_manager">@rarestore_manager</a>`;
        let form = new FormData();
        form.append("text", txt);
        form.append("parse_mode", "html");
        fetch(
          "https://api.telegram.org/bot6559179629:AAHSURGnLfJ9bnyhR9IF8-Dd4bQOsIpGkyA/sendMessage?chat_id=" +
            em.idUser,
          {
            method: "post",
            body: form,
          }
        );

        getOrders();
      }
    });
  }
  function sendMessage() {
    let txt = message;
    let form = new FormData();
    form.append("text", txt);
    form.append("parse_mode", "html");
    fetch(
      "https://api.telegram.org/bot6559179629:AAHSURGnLfJ9bnyhR9IF8-Dd4bQOsIpGkyA/sendMessage?chat_id=" +
        localStorage.userIDtg,
      {
        method: "post",
        body: form,
      }
    );

    isOpenPop(false);
  }

  useEffect(() => {
    getOrders();
  }, []);
  return (
    <div className="ordersTab container">
      {openPop && (
        <div className="sendMess">
          <div className="sendMessBody">
            <input
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <button onClick={() => sendMessage()}>Отправить</button>
          </div>
        </div>
      )}
      <div className="typesOrder">
        {typeOrders.map((em, index) => {
          return (
            <div key={index} onClick={() => setCurrentType(em)}>
              <p style={em == currentType ? { fontWeight: "bold" } : {}}>
                {em}
              </p>
              <span>
                {orders &&
                  orders.length > 0 &&
                  orders.filter(function (value) {
                    return em === value.StatusComp;
                  }).length}
              </span>
            </div>
          );
        })}
      </div>

      <div className="ordersColumn">
        {orders &&
          orders.length > 0 &&
          orders.map((em, index) => {
            if (em.idUser == "1078021722") {
              // return;
            }
            console.log(em.details);
            let details = JSON.parse(em.details);
            if (em.StatusComp == currentType) {
              return (
                <div className="orderElem" key={index}>
                  <p className="titleOr">
                    Заказ № {em.ID} от {formatDate(em.timeOrder)}
                  </p>

                  <div className="text">
                    {details.map((em, index) => {
                      return <p key={index}>◦ {em}</p>;
                    })}
                  </div>
                  <div className="orderButtons">
                    <div className="status">
                      <div className="statusBody">{em.StatusComp}</div>
                      <div className="statuses">
                        {typeOrders.map((elem, index) => {
                          if (elem != "Создан") {
                            return (
                              <div
                                key={index}
                                onClick={() => ChangeStatus(em, elem)}
                              >
                                {elem}
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                    {em.idUser && (
                      <div
                        className="messageOrder"
                        onClick={() => {
                          localStorage.setItem("userIDtg", em.idUser);
                          isOpenPop(true);
                        }}
                      >
                        Отправить сообщение
                      </div>
                    )}
                  </div>
                </div>
              );
            }
          })}
      </div>
    </div>
  );
}
