import { useState } from "react";
import "./style.css";
export default function Zad() {
  const [link, setLink] = useState("");
  return (
    <div className="container zad">
      <ul>
        <li>Зарегистрировать профиль </li>
        <li>Заполнить анкету</li>
        <li>Посмотреть бонусный урок 1/2/3/4</li>
        <li>
          Подписаться на тг канал{" "}
          <input type="text" placeholder="Ссылка на тг канал" />
        </li>
        <li>Зарегистрироваться на вебинар</li>
        <li>Зарегистрироваться в Партнерской Программе</li>
        <li>Пригласить друга</li>
        <li>Купить курс 1/2/3/4/5</li>
      </ul>
    </div>
  );
}
