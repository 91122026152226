import "./style.css";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";

export default function AdminMainTable() {
  const [users, setUsers] = useState([]);
  const Navigate = useNavigate();
  let input = useRef(null);
  const copytext = (ref) => {
    input.current.value = ref;
    console.log(input.current);
    input.current.select();
    document.execCommand("copy");
  };
  const getUsers = () => {
    fetch("https://app.opora.digital/getUsers.php")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setUsers(data);
      });
  };
  const changeUser = (ID, ACTIVE) => {
    let id = ID;
    let active = ACTIVE;
    let form = new FormData();
    form.append("id", id);
    form.append("active", active);
    fetch("https://ultroninfobot.store/change.php", {
      method: "POST",
      body: form,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        getUsers();
      });
  };
  useEffect(() => {
    getUsers();
    fetch("https://app.opora.digital/test.php");
  }, []);

  return (
    <div className="utc_main" style={{ marginLeft: "0" }}>
      <input
        type="text"
        name="fff"
        id="hidden__input"
        ref={input}
        style={{ transform: "translateX(-10000px)" }}
      />
      <div className="utc_main__table">
        {/*  <button
          className="wp_header_sections_link"
          style={{
            marginLeft: "51px",
            marginBottom: "2vw",
            outline: "none",
            border: "none",
          }}
          onClick={() => Navigate(routes.welcomePage)}
        >
          На главную
        </button> */}
        <table cellspacing="0" className="utc_main__table__main">
          <tr className="utc_main__table__header">
            <td className="utc_main__table__header__fio">ФИО</td>
            <td className="utc_main__table__header__tel">телефон</td>
            <td className="utc_main__table__header__pass">пароль</td>
            <td className="utc_main__table__header__pass">Компания</td>
            <td className="utc_main__table__header__act">активация</td>
          </tr>

          {users &&
            users.length > 0 &&
            users.map((em) => {
              return (
                <tr className="utc_main__table__main__user" key={em["ID"]}>
                  <td className="utc_main__table__main__user__fio">
                    {em["ФИО"]}
                  </td>
                  <td className="utc_main__table__main__user__tel">
                    {em["Телефон"]}
                  </td>
                  <td className="utc_main__table__main__user__pass">
                    {em["Пароль"]}
                  </td>
                  <td className="utc_main__table__main__user__code">
                    {em["Компания"]}
                  </td>

                  <td className="utc_main__table__main__user__act">
                    {em["АктивацияID"] == 1 ? (
                      <div
                        className="acc__active"
                        onClick={() => {
                          changeUser(em.ID, 0);
                        }}
                      >
                        Активен
                      </div>
                    ) : (
                      <div
                        className="acc__disable"
                        onClick={() => {
                          changeUser(em.ID, 1);
                        }}
                      >
                        Не активен
                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
        </table>
      </div>
    </div>
  );
}
