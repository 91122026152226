import { useEffect, useState } from "react";
import "./style.css";
import { Store } from "react-notifications-component";
import { getCourses } from "../../Api";
export default function Courses() {
  const [courses, setCourses] = useState([]);
  const [title, setTitle] = useState("");
  const [icon, setIcon] = useState("");
  const [id, setID] = useState("");
  const [desc, setDesc] = useState("");
  const [price, setPrice] = useState("");
  const [frame, setFrame] = useState("");
  const [currentCurse, setCurrentCurse] = useState("");
  //Меняем
  const [changeTitle, setChangeTitle] = useState("");
  const [changeId, setChangeId] = useState("");
  const [changePrice, setChangePrice] = useState("");
  const [changeDesc, setChangeDesc] = useState("");
  const [changeFrame, setChangeFrame] = useState("");
  const [pop, setPop] = useState("");
  useEffect(() => {
    getCourses().then((data) => {
      setCourses(data);
    });
  }, []);
  function saveCurse() {
    let formData = new FormData();
    formData.append("title", title);
    formData.append("ID", id.replaceAll("'", "`"));
    formData.append("desc", desc);
    formData.append("price", price);
    formData.append("frame", frame.replaceAll("'", "`"));
    fetch("https://app.opora.digital/taro/addCourse.php", {
      method: "POST",
      body: formData,
    }).then((response) => {
      Store.addNotification({
        title: "Уведомление",
        message: "Курс успешно добавлен",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__bounceIn"],
        animationOut: ["animate__animated", "animate__backOutUp"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      /*  setFrame("");
      setTitle("");
      setDesc("");
      setID("");
      setPrice(""); */
      getCourses().then((data) => {
        setCourses(data);
      });
    });
  }
  useEffect(() => {
    console.log(currentCurse);
    if (currentCurse) {
      setChangeTitle(currentCurse.Title);
      setChangePrice(currentCurse.Price);
      setChangeFrame(currentCurse.Frame);
      setChangeId(currentCurse.Img);
      setChangeDesc(currentCurse.DescCard);
    }
  }, [currentCurse]);
  function deleteCurse() {
    let formData = new FormData();

    formData.append("ID", currentCurse.ID);
    fetch("https://app.opora.digital/taro/deleteCourse.php", {
      method: "POST",
      body: formData,
    }).then((response) => {
      Store.addNotification({
        title: "Уведомление",
        message: "Курс успешно Удален",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__bounceIn"],
        animationOut: ["animate__animated", "animate__backOutUp"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      getCourses().then((data) => {
        setCourses(data);
      });
    });
  }
  function changeCurse() {
    let formData = new FormData();

    formData.append("ID", changeId.replaceAll("'", "`"));
    formData.append("mid", currentCurse.ID);
    formData.append("title", changeTitle);

    formData.append("desc", changeDesc);
    formData.append("price", changePrice);
    formData.append("frame", changeFrame.replaceAll("'", "`"));
    fetch("https://app.opora.digital/taro/changeCourse.php", {
      method: "POST",
      body: formData,
    }).then((response) => {
      Store.addNotification({
        title: "Уведомление",
        message: "Курс успешно изменен",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__bounceIn"],
        animationOut: ["animate__animated", "animate__backOutUp"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      setPop(false);
      getCourses().then((data) => {
        setCourses(data);
      });
    });
  }
  return (
    <div className="container flexCont">
      {pop && (
        <div
          className="popaddCurse"
          id="clsa"
          onClick={(e) => {
            if (e.target.id == "clsa") {
              setPop(false);
            }
          }}
        >
          <div className="addForm">
            <h3>Изменить курс</h3>
            <input
              type="text"
              value={changeTitle}
              onChange={(e) => setChangeTitle(e.target.value)}
              placeholder="Заголовок"
            />

            <input
              type="text"
              value={changeId}
              onChange={(e) => setChangeId(e.target.value)}
              placeholder="Видео rutube"
            />
            <input
              type="text"
              value={changeDesc}
              onChange={(e) => setChangeDesc(e.target.value)}
              placeholder="Описание"
            />
            <input
              type="text"
              value={changePrice}
              onChange={(e) => setChangePrice(e.target.value)}
              placeholder="Цена"
            />
            <input
              type="text"
              value={changeFrame}
              onChange={(e) => setChangeFrame(e.target.value)}
              placeholder="Фрейм"
            />
            <div className="addCourse" onClick={() => changeCurse()}>
              Изменить
            </div>
          </div>
        </div>
      )}
      <div className="coursesItems">
        {courses &&
          courses.length > 0 &&
          courses.map((em, index) => {
            return (
              <div
                className="setCour"
                style={
                  currentCurse.ID == em.ID ? { border: "1px solid black" } : {}
                }
                onClick={() => setCurrentCurse(em)}
                key={index}
              >
                {em.Title}
              </div>
            );
          })}
      </div>
      <div className="panelRed">
        <p onClick={() => deleteCurse()}>Удалить</p>
        <p onClick={() => setPop(true)}>Редактировать</p>
      </div>
      <div className="addForm">
        <h3>Добавить курс</h3>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Заголовок"
        />

        <input
          type="text"
          value={id}
          onChange={(e) => setID(e.target.value)}
          placeholder="Видео rutube"
        />
        <input
          type="text"
          value={desc}
          onChange={(e) => setDesc(e.target.value)}
          placeholder="Описание"
        />
        <input
          type="text"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          placeholder="Цена"
        />
        <input
          type="text"
          value={frame}
          onChange={(e) => setFrame(e.target.value)}
          placeholder="Фрейм"
        />
        <div className="addCourse" onClick={() => saveCurse()}>
          Добавить
        </div>
      </div>
    </div>
  );
}
