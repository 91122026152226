import "./style.css";
import dogovor from "./dogovor.svg";
import sale from "./sale.svg";
import help from "./ask.svg";
import contacts from "./cotacts.svg";
import profile from "./ava.png";
import { routes } from "../routes";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../Context";
import { useContext } from "react";

export default function Header() {
  const [context, setContext] = useContext(Context);
  const Navigate = useNavigate();
  return (
    <div className="header">
      {window.location.pathname == routes.actions && (
        <div className="title_str">Акции</div>
      )}
      {window.location.pathname == routes.clients && (
        <div className="title_str">Пользователи</div>
      )}
      {window.location.pathname == routes.services && (
        <div className="title_str">Услуги</div>
      )}
      {window.location.pathname == routes.doctors && (
        <div className="title_str">Врачи</div>
      )}
      {window.location.pathname == routes.main && (
        <div className="title_str">Главная</div>
      )}
      {window.location.pathname == routes.contacts && (
        <div className="title_str">Контакты</div>
      )}
      {window.location.pathname == routes.dop && (
        <div className="title_str">материалы</div>
      )}
      {window.location.pathname == routes.courses && (
        <div className="title_str">Курсы</div>
      )}
      {window.location.pathname == "/reviews" && (
        <div className="title_str">Отзывы</div>
      )}
      {/* <Link to={routes.dogovor} target="_blank">
        <div className="dogovor header_button">
          <img src={dogovor} alt="" />
          <p>Ваш договор</p>
        </div>
      </Link> */}

      {/*   <div className="header_button sale">
        <img src={sale} alt="" />
        <p>Оплата</p>
      </div> */}
      <a href="https://t.me/oporadigital_bot" target="_blank">
        <div className="header_button">
          <img src={help} alt="" />
          <p>Помощь</p>
        </div>
      </a>

      <div
        className="header_button"
        onClick={() => {
          Navigate(routes.contacts);
        }}
      >
        <img src={contacts} alt="" />
        <p>Контакты</p>
      </div>
      <img
        src={profile}
        alt=""
        className="profile"
        onClick={() => {
          Navigate(routes.login);
          localStorage.clear();
          window.location.reload();
        }}
      />
    </div>
  );
}
