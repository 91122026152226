import { useEffect, useRef, useState } from "react";
import "./style.css";
import cloud from "../../imgs/cloud.svg";
import ok from "./ok.png";
import delete2 from "../../imgs/delete.svg";
import Notification from "../../Components/Notification";
import AnimatedPage from "../../animatedPage";
export default function Actions() {
  const [stocks, setStocks] = useState([]);
  const [notification, setNotification] = useState("");
  const [currentAction, setCurrentAction] = useState();
  const [file1, setFile1] = useState(false);
  const [file2, setFile2] = useState(false);
  const [link, setLink] = useState("");
  const fileName = useRef(null);
  const fileName2 = useRef(null);
  function deleteMe(id) {
    let formData = new FormData();
    formData.append("ID", id);
    fetch("https://app.opora.digital/deleteStock.php", {
      method: "POST",
      body: formData,
    }).then((response) => {
      if (response.status == 200) {
        getNotification("Акция удалена");
        getStock();
      }
    });
  }
  function getNotification(string) {
    setNotification(string);
    let div = document.getElementsByClassName("notification")[0];
    div.style = "top: 1vw";
    setTimeout(() => {
      div.style = "top: -5vw";
    }, 2000);
  }
  function onChange() {
    let saveData = new FormData();
    saveData.append("path1", link);
    saveData.append("path2", link);
    saveData.append("ID", localStorage.id);
    fetch("https://app.opora.digital/avatar2.php", {
      method: "post",
      body: saveData,
    }).then((response) => {
      if (response.status == 200) {
        setFile1(false);
        setFile2(false);
        setLink("");
        document.getElementById("first").value = "";
        document.getElementById("second").value = "";
        getNotification("Отзыв добавлен");
        getStock();
      }
    });
  }
  function getStock() {
    let formData = new FormData();
    formData.append("ID", localStorage.id);
    fetch("https://app.opora.digital/getStocks.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setStocks(data);
      });
  }
  useEffect(() => {
    getStock();
  }, []);
  return (
    <AnimatedPage>
      <div className="container">
        <div className="flexCont">
          <Notification text={notification} />
          <div className="actionsCont">
            {stocks &&
              stocks.length > 0 &&
              stocks.map((img, index) => (
                <div
                  key={index}
                  className="actions"
                  onClick={() => {
                    setCurrentAction(index);
                  }}
                >
                  <p style={{ height: "10vw" }}>{img.imgList}</p>
                  {index == currentAction && (
                    <div className="panel">
                      <div
                        className="panEm"
                        onClick={() => deleteMe(img.idStock)}
                      >
                        <img src={delete2} alt="" />
                        <p>Удалить</p>
                      </div>
                    </div>
                  )}
                </div>
              ))}
          </div>
          <div className="formActions">
            <p className="add_me">Добавить Отзыв</p>
            <input
              type="text"
              placeholder="Ссылка"
              value={link}
              onChange={(e) => setLink(e.target.value)}
            />
            {localStorage.tel != "+7 (980) 800 8080" && (
              <div className="imgAdd">
                <p>
                  Загрузите первое <br /> изображение
                </p>
                <input
                  type="file"
                  onChange={() => {
                    setFile1(true);
                  }}
                  name="file"
                  ref={fileName}
                  id="first"
                />
                <img src={file1 ? ok : cloud} alt="" />
                <label htmlFor="first">Загрузить</label>
              </div>
            )}{" "}
            {localStorage.tel != "+7 (980) 800 8080" && (
              <div className="imgAdd">
                <p>
                  Загрузите второе <br /> изображение
                </p>
                <input
                  type="file"
                  onChange={() => {
                    setFile2(true);
                  }}
                  name="file"
                  ref={fileName2}
                  id="second"
                />
                <img src={file2 ? ok : cloud} alt="" />
                <label htmlFor="second">Загрузить</label>
              </div>
            )}
            <div className="button" onClick={onChange}>
              Сохранить
            </div>
          </div>
        </div>
      </div>
    </AnimatedPage>
  );
}
