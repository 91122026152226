import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { faker } from "@faker-js/faker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "График прироста аудитории",
    },
  },
};

const labels = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль"];

export default function Grafic() {
  const [labels, setLabels] = useState([]);
  const [mounts, setMounts] = useState([]);
  const [counter, setCounter] = useState({});
  const formatDate = (date) => {
    let d = new Date(date);
    let month = (d.getMonth() + 1).toString();
    let day = d.getDate().toString();
    let year = d.getFullYear();
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    return [year, month, day].join("-");
  };
  function datediff(first, second) {
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }

  /**
   * new Date("dateString") is browser-dependent and discouraged, so we'll write
   * a simple parse function for U.S. date format (which does no error checking)
   */
  function parseDate(str) {
    var mdy = str.split("-");
    return new Date(mdy[0], mdy[1] - 1, mdy[2]);
  }

  function daysInMonth(month) {
    // Use 1 for January, 2 for February, etc.
    return new Date(2023, month, 0).getDate();
  }
  const getUsers = () => {
    let formData = new FormData();
    formData.append("ID", localStorage.id);
    fetch("https://app.opora.digital/api/usersSt/GetUsersStom.php", {
      method: "post",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let arr = data.map((em) => {
          return formatDate(em.dateReg);
        });
        console.log(arr);
        let arrDub = [];
        //arrMounth = [...new Set(arrMounth)];
        for (let i = 0; i < arr.length; i++) {
          let f = arr[i].split("-")[1];
          if (f[0] == "0") {
            f = f.substr(1, f.length);
          }
          arrDub.push(f);
        }
        let MAIN_ARR = [];
        let news = [...new Set(arrDub)];
        for (let i = 0; i < news.length; i++) {
          let num = daysInMonth(news[i]);
          console.log(num);
          for (let j = 1; j <= Number(num); j++) {
            let date = "";
            if (j.toString().length == 1) {
              date += "0";
            }
            date += j;
            let curDate = "2023-" + news[i] + "-" + date;
            MAIN_ARR.push(curDate);
          }
        }
        console.log(MAIN_ARR);

        let counts = {};
        let dub = [...new Set(arr)];
        setLabels(dub);
        arr.forEach(function (x) {
          counts[x] = (counts[x] || 0) + 1;
        });
        setCounter(counts);
      });
  };

  useEffect(() => {
    getUsers();
  }, []);
  return (
    <Line
      width={100}
      height={30}
      options={{
        labels,
        datasets: [
          {
            fill: true,
            label: "Анализ прироста",
            data: labels.map((em) => counter[em]),
            borderColor: "rgb(53, 162, 235)",
            backgroundColor: "rgba(196, 235, 248, 0.67)",
          },
        ],
      }}
      data={{
        labels,
        datasets: [
          {
            fill: true,
            label: "Анализ прироста",
            data: labels.map((em) => counter[em]),
            borderColor: "rgb(53, 162, 235)",
            backgroundColor: "rgba(196, 235, 248, 0.67)",
          },
        ],
      }}
    />
  );
}
