import { useEffect, useState } from "react";
import ava from "./ava.png";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../routes";
import "./style.css";
export default function Activation() {
  const [users, setUsers] = useState([]);
  const Navigate = useNavigate();
  function getUsers() {
    fetch("https://app.opora.digital/bali/getUsers.php")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setUsers(data);
      });
  }
  function updateActivation(id, act) {
    let formdata = new FormData();
    formdata.append("id", id);
    if (act == 1) {
      formdata.append("act", 0);
    } else {
      formdata.append("act", 1);
    }
    fetch("https://app.opora.digital/bali/updateStatus.php", {
      method: "POST",
      body: formdata,
    }).then((response) => {
      if (response.status == 200) {
        let form = new FormData();
        let txt = "Ваш аккаунт успешно активирован ✅";
        let txt2 = "Ваш аккаунт деактивирован ❌";
        act == 0 ? form.append("text", txt) : form.append("text", txt2);
        form.append("parse_mode", "html");
        fetch(
          `https://api.telegram.org/bot${localStorage.token}/sendMessage?chat_id=` +
            id,
          {
            method: "post",
            body: form,
          }
        );

        getUsers();
      }
    });
  }
  function updateRegistration(id, act) {
    let formdata = new FormData();
    formdata.append("id", id);
    if (act == 1) {
      formdata.append("act", 0);
    } else {
      formdata.append("act", 1);
    }
    fetch("https://app.opora.digital/bali/updateReg.php", {
      method: "POST",
      body: formdata,
    }).then((response) => {
      if (response.status == 200) {
        let form = new FormData();
        let txt = "Регистрация подтверждена ✅";
        let txt2 = "Ваш аккаунт деактивирован ❌";
        act == 0 ? form.append("text", txt) : form.append("text", txt2);
        form.append("parse_mode", "html");
        fetch(
          `https://api.telegram.org/bot${localStorage.token}/sendMessage?chat_id=` +
            id,
          {
            method: "post",
            body: form,
          }
        );

        getUsers();
      }
    });
  }
  const changeOwn = (id, idOwn) => {
    let formData = new FormData();
    formData.append("id", id);
    formData.append("act", idOwn);
    fetch("https://app.opora.digital/bali/updateRef.php", {
      method: "POST",
      body: formData,
    }).then((response) => {
      if (response.status == 200) {
        getUsers();
      }
    });
  };
  useEffect(() => {
    getUsers();
  }, []);
  return (
    <div className="container use2">
      <div className="table">
        {users && users.length > 0 ? (
          <div className="rowT frowT">
            <img src={ava} alt="" style={{ opacity: "0" }} />
            <div style={{ width: "3vw" }}>ID</div>
            <div>usernameTg</div>
            <div>Phone</div>
            <div>Дата регистрации</div>
            <div style={{ width: "7vw" }}>Активация акк.</div>
            <div>Активация рег.</div>
            <div>ID реф</div>
          </div>
        ) : (
          <div>В вашем боте пока нет зарегистрированных пользователей</div>
        )}
        {users &&
          users.length > 0 &&
          users.map((em, index) => {
            if (em.usernameTg != "undefined") {
              return (
                <div
                  key={index}
                  className="rowT"
                  onClick={() => {
                    Navigate(
                      routes.chat +
                        "?key=" +
                        em.chatId +
                        "&name=" +
                        em.usernameTg
                    );
                  }}
                >
                  <img src={ava} alt="" />
                  <div style={{ width: "3vw" }}>{em.ID}</div>
                  <div>{"@" + em.usernameTg}</div>
                  <div>{em.Phone}</div>
                  <div>{em.DateRegister}</div>
                  <div style={{ width: "7vw" }}>
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      value={em.chatId}
                      defaultChecked={em.Activated == 1 ? true : false}
                      onClick={(e) => {
                        e.stopPropagation();
                        updateActivation(e.target.value, em.Activated);
                      }}
                    />
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      value={em.chatId}
                      defaultChecked={
                        em.ConfirmRegistration == 1 ? true : false
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                        updateRegistration(
                          e.target.value,
                          em.ConfirmRegistration
                        );
                      }}
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      value={em.idOwn || ""}
                      onClick={(e) => e.stopPropagation()}
                      onChange={(e) => {
                        changeOwn(em.ID, e.target.value);
                      }}
                    />
                  </div>
                </div>
              );
            }
          })}
      </div>
    </div>
  );
}
