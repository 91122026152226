import { useEffect, useRef, useState } from "react";
import ava from "./ava.jpg";
import "./style.css";
import AnimatedPage from "../../animatedPage";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
export default function Clients() {
  const [users, setUsers] = useState();
  const [message, setMessage] = useState("");
  const fileName = useRef(null);
  const [pop, isPop] = useState(false);
  const [load, isload] = useState(false);
  const getUsers = () => {
    let formData = new FormData();
    formData.append("ID", localStorage.id);
    fetch("https://app.opora.digital/api/usersSt/GetUsersStom.php", {
      method: "post",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setUsers(data);
      });
  };
  const Navigate = useNavigate();
  function sendMessage() {
    let file = fileName.current.files[0];
    let formData = new FormData();
    formData.append("file", file);
    fetch("https://app.opora.digital/avatar.php", {
      method: "post",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let path = "https://app.opora.digital/" + data;
        let formD = new FormData();
        formD.append("imgLink", path);
        formD.append("chatId", 675264984);
        formD.append("name", message);
        fetch("https://app.opora.digital/sendM.php", {
          method: "post",
          body: formD,
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            console.log(data);
          });
      });
  }
  function postMessage(id, form, i) {
    fetch(
      "https://api.telegram.org/bot" +
        localStorage.token +
        "/sendPhoto?chat_id=" +
        id,
      {
        method: "post",
        body: form,
      }
    );
    if (i == users.length - 1) {
      isPop(false);
      isload(false);
    }
  }
  function sendMessagse() {
    let formD = new FormData();
    formD.append("photo", fileName.current.files[0]);
    let i;
    formD.append("caption", message);

    let arr = [];
    for (i = 0; i < users.length; i++) {
      if (users[i].firstBuy == "0") {
        arr.push(users[i].idTg);
      }
    }
    arr = [...new Set(arr)];

    for (i = 0; i < arr.length; i++) {
      setTimeout(postMessage(users[i].idTg, formD, i), 200 * i);
    }
  }
  function testM() {
    let formD = new FormData();
    formD.append("document", fileName.current.files[0]);
    formD.append("parse_mode", "HTML");
    let i;
    formD.append("caption", message);
    fetch(
      "https://api.telegram.org/bot" +
        localStorage.token +
        "/sendDocument?chat_id=675264984",
      {
        method: "post",
        body: formD,
      }
    );
  }
  useEffect(() => {
    getUsers();
  }, []);
  return (
    <AnimatedPage>
      <div
        className="container"
        style={{ display: "flex", alignItems: "flex-start" }}
      >
        {pop && (
          <div className="addMessage">
            <div className="addMessageBody">
              <h4>Текст рассылки</h4>
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
              <h4>Изображение</h4>
              <input
                type="file"
                ref={fileName}
                name=""
                onChange={() => {
                  isload(true);
                }}
                id="sends"
                style={{ display: "none" }}
              />
              <label htmlFor="sends" style={load ? { display: "none" } : {}}>
                {" "}
                Загрузить{" "}
              </label>
              <div className="button" onClick={() => sendMessagse()}>
                Отправить
              </div>
            </div>
          </div>
        )}
        <div className="table">
          {users && users.length > 0 ? (
            <div className="rowT frowT">
              <img src={ava} alt="" style={{ opacity: "0" }} />
              <div>Имя</div>
              <div>username Telegram</div>
              <div>Дата регистрации</div>
              <div>Последний сеанс</div>
            </div>
          ) : (
            <div>В вашем боте пока нет зарегистрированных пользователей</div>
          )}
          {users &&
            users.length > 0 &&
            users.map((em, index) => {
              return (
                <div
                  key={index}
                  className="rowT"
                  onClick={() => {
                    Navigate(
                      routes.chat + "?key=" + em.idTg + "&name=" + em.username
                    );
                  }}
                >
                  <img src={ava} alt="" />
                  <div>{em.username}</div>
                  <div>@{em.usernameTg}</div>
                  <div>{em.dateReg}</div>
                  <div>{em.lastSeans}</div>
                </div>
              );
            })}
        </div>
        {users && users.length > 0 && (
          <div
            className="sender"
            onClick={() => {
              isPop(true);
            }}
          >
            Рассылка
          </div>
        )}
      </div>
    </AnimatedPage>
  );
}
