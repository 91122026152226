import { useEffect, useState } from "react";
import "./style.css";
import delete2 from "../../imgs/delete.svg";
import Notification from "../../Components/Notification";
import AnimatedPage from "../../animatedPage";
import { useContext } from "react";
import { Voice } from "../../Voice";
export default function Services() {
  const [services, setServices] = useState([]);
  const [voice, setVoice] = useContext(Voice);
  const [serviceSearch, setServiceSearch] = useState("");
  const [service, setCurrentService] = useState("");
  const [serviceName, setSerceName] = useState("");
  const [servicePrice, setSercePrice] = useState("");
  const [serviceTitle, setServiceTitle] = useState("");
  const [notificationText, setNotificationText] = useState("sss");
  const [focused, setFocused] = useState(false);
  function getNotification(string) {
    setNotificationText(string);
    let div = document.getElementsByClassName("notification")[0];
    div.style = "top: 1vw";
    setTimeout(() => {
      div.style = "top: -5vw";
    }, 2000);
  }
  useEffect(() => {
    if (voice.includes("найди услугу")) {
      setServiceSearch(voice.replace("найди услугу ", ""));
    }
    if (voice.includes("очисти фильтр")) {
      setServiceSearch("");
    }
    if (voice.includes("удали услугу")) {
      if (services && services.length > 0) {
        services.map((em) => {
          em.cards.map((em1) => {
            if (em1.nameService.includes(voice.replace("удали услугу ", ""))) {
              deleteService(em1.id);
            }
          });
        });
      }
    }
  }, [voice]);
  const getServices = () => {
    let formData = new FormData();
    formData.append("ID", localStorage.id);
    fetch("https://app.opora.digital/getServices.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setServices(data);
      });
  };
  useEffect(() => {
    getServices();
  }, []);
  const addService = () => {
    let formData = new FormData();
    formData.append("ID", localStorage.id);
    formData.append("nameService", serviceName);
    formData.append("price", servicePrice);
    formData.append("title", serviceTitle);
    fetch("https://app.opora.digital/addService.php", {
      method: "POST",
      body: formData,
    }).then((response) => {
      if (response.status == 200) {
        getNotification("Вопрос добавлен");
        getServices();
        setSercePrice("");
        setSerceName("");
        setServiceTitle("");
      }
    });
  };
  const deleteService = (id) => {
    let formData = new FormData();
    formData.append("ID", id);
    fetch("https://app.opora.digital/deleteService.php", {
      method: "POST",
      body: formData,
    }).then((response) => {
      if (response.status == 200) {
        getNotification("Вопрос удален");
        getServices();
      }
    });
  };

  return (
    <AnimatedPage>
      <div
        className="container"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Notification text={notificationText} />
        <div className="services">
          {services &&
            services.length > 0 &&
            services.map((em, index) => {
              return (
                <div key={index} className="section">
                  {serviceSearch.length == 0 && (
                    <p
                      className="search_services_first_section"
                      style={index == 0 ? { marginTop: "0" } : {}}
                    >
                      {em.type}
                    </p>
                  )}

                  {em.cards &&
                    em.cards.length > 0 &&
                    em.cards.map((elem, index2) => {
                      if (
                        elem.nameService
                          .toLowerCase()
                          .includes(serviceSearch.toLowerCase())
                      )
                        return (
                          <div
                            key={em.type + index2}
                            className="service_em"
                            onClick={() => {
                              setCurrentService(em.type + index2);
                            }}
                          >
                            <p className="service_name">{elem.nameService}</p>
                            <p className="service_price">
                              {elem.price != "-"
                                ? elem.price + " руб."
                                : "Бесплатно"}{" "}
                            </p>
                            {em.type + index2 == service && (
                              <div className="panel">
                                <div
                                  className="panEm"
                                  onClick={() => {
                                    deleteService(elem.id);
                                  }}
                                >
                                  <img src={delete2} alt="" />
                                  <p>Удалить</p>
                                </div>
                              </div>
                            )}
                          </div>
                        );
                    })}
                </div>
              );
            })}
        </div>
        <div className="search">
          <h3>
            {" "}
            {localStorage.tel == "+7 (980) 800 8080"
              ? "Поиск по вопросу"
              : "Поиск по имени услуги"}
          </h3>
          <input
            type="text"
            value={serviceSearch}
            onChange={(e) => {
              setServiceSearch(e.target.value);
            }}
          />
        </div>
        <div className="addService">
          <div className="addServiceNew">
            <h3>
              {localStorage.tel == "+7 (980) 800 8080"
                ? "Добавить вопрос"
                : "Добавить услугу"}
            </h3>
            <textarea
              style={
                serviceName.length > 0 ? { border: "1px solid #008AED" } : {}
              }
              onInput={(e) => {
                setSerceName(e.target.value);
              }}
              name=""
              id=""
              cols="30"
              rows="4"
              value={serviceName}
              placeholder={
                localStorage.tel == "+7 (980) 800 8080"
                  ? "Вопрос"
                  : "Наименование услуги"
              }
            ></textarea>
            {localStorage.tel != "+7 (980) 800 8080" && (
              <input
                style={
                  servicePrice.length > 0 ? { border: "1px solid #008AED" } : {}
                }
                type="number"
                placeholder="Цена"
                value={servicePrice}
                onInput={(e) => {
                  setSercePrice(e.target.value);
                }}
              />
            )}
            <div style={{ width: "100%", position: "relative" }}>
              <input
                type="text"
                onBlur={() => {
                  setFocused(false);
                }}
                style={
                  serviceTitle.length > 0 ? { border: "1px solid #008AED" } : {}
                }
                onFocus={() => {
                  setFocused(true);
                }}
                on
                placeholder="Ответ"
                value={serviceTitle}
                onInput={(e) => {
                  setServiceTitle(e.target.value);
                }}
              />
              {serviceTitle.length > 0 && (
                <div className="categoryList">
                  {services &&
                    services.length > 0 &&
                    services.map((em, index) => {
                      if (
                        em.type
                          .toLowerCase()
                          .includes(serviceTitle.toLowerCase()) &&
                        serviceTitle.toLowerCase() != em.type.toLowerCase()
                      ) {
                        return (
                          <div
                            className="cat_em"
                            onClick={() => setServiceTitle(em.type)}
                            key={index}
                          >
                            {em.type}
                          </div>
                        );
                      }
                    })}
                </div>
              )}
            </div>
            <div
              className="button"
              style={
                serviceName.length > 0 && serviceTitle.length > 0
                  ? {}
                  : { opacity: "0.6", pointerEvents: "none" }
              }
              onClick={() => {
                serviceName.length > 0 &&
                  serviceTitle.length > 0 &&
                  addService();
              }}
            >
              Добавить
            </div>
          </div>
        </div>
      </div>
    </AnimatedPage>
  );
}
